import React, { useState, useEffect } from "react";
import Autocomplete from "react-google-autocomplete";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faCalendar } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";
import Loading from "../Loading/Loader";
import Axios from "axios";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { TransferSearchResponse } from "../../Redux/Actions/actions";
import { NewTransferSearchToken, ApiEndPoint } from "../GlobalData/GlobalData";
function TransferNew() {
  const [pickUp, setPickUp] = useState(null);
  const [dropOf, setDropOf] = useState(null);
  const [otherData, setOtherData] = useState({
    pickupDate: null,
    pickupTime: "",
    DropOffDate: null,
    DropOffTime: "",
  });
  const [tripType, setTripType] = useState("One-Way");
  const [Passenger, setPassenger] = useState("1");
  const [Vehicles, setVehicles] = useState("1");
  const [isLoading, setIsLoading] = useState(false);
  const tokenApi = NewTransferSearchToken();
  const endpoint = ApiEndPoint();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleOtherData = (event) => {
    const { value, name } = event.target;
    setOtherData({ ...otherData, [name]: value });
  };
  const handlePickupDate = (date) => {
    setOtherData({ ...otherData, pickupDate: date, DropOffDate: date });
  };
  const handleDropoffDate = (date) => {
    setOtherData({ ...otherData, DropOffDate: date });
  };
  const handleSelectTripType = (event) => {
    setTripType(event.target.value);
  };

  const handlePassenger = (event) => {
    setPassenger(event.target.value);
  };
  const handleVehicles = (event) => {
    setVehicles(event.target.value);
  };
  const searchTransfer = async () => {
    if (pickUp === null) {
      toast.info("Please Select Pickup Location.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (Object.keys(pickUp).length === 0) {
      toast.info("Please Select Pickup Location.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (dropOf === null) {
      toast.info("Please Select DropOff Location.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (Object.keys(dropOf).length === 0) {
      toast.info("Please Select DropOff Location.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (otherData.pickupDate === "" || otherData.pickupDate === null) {
      toast.info("Please Select Pickup Date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (otherData.pickupTime === "") {
      toast.info("Please Select Pickup Time.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (otherData.DropOffDate === "") {
      toast.info("Please Select Dropoff Date.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (otherData.DropOffTime === "") {
      toast.info("Please Select Dropoff Time.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (Passenger === "") {
      toast.info("Please Enter Passenger.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    } else if (Vehicles === "") {
      toast.info("Please Enter Vehicle.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var PickupCountry;
    if (
      pickUp.address_components.filter(
        (f) =>
          JSON.stringify(f.types) === JSON.stringify(["country", "political"])
      )[0]
    ) {
      PickupCountry = pickUp.address_components.filter(
        (f) =>
          JSON.stringify(f.types) === JSON.stringify(["country", "political"])
      )[0]?.long_name;
    }

    var PickData = document.getElementsByName("Pickup");
    var PickupLocation = PickData[0].value;
    var Picklatitude = pickUp.geometry.location.lat();
    var Picklongitude = pickUp.geometry.location.lng();
    const PickupAddress = pickUp.formatted_address;

    var DropOffCountry;
    if (
      dropOf.address_components.filter(
        (f) =>
          JSON.stringify(f.types) === JSON.stringify(["country", "political"])
      )[0]
    ) {
      DropOffCountry = dropOf.address_components.filter(
        (f) =>
          JSON.stringify(f.types) === JSON.stringify(["country", "political"])
      )[0]?.long_name;
    }

    var DropData = document.getElementsByName("Dropoff");
    var DropoffLocation = DropData[0].value;
    var Dropoflatitude = dropOf.geometry.location.lat();
    var Dropoflongitude = dropOf.geometry.location.lng();
    const DropofAddress = dropOf.formatted_address;
    var data = {
      site_URL: "https://system.alhijaztours.net/public/uploads/package_imgs",
      token: tokenApi,
      name_pickup_location_plc: PickupLocation,
      name_drop_off_location_plc: DropoffLocation,
      pick_up_date: moment(otherData.pickupDate).format("YYYY-MM-DD"),
      trip_type: tripType,
      passenger: String(Passenger),
      no_of_vehicles: String(Vehicles),
      pick_up_location_country: PickupCountry,
      startName: String(Picklatitude + "," + Picklongitude),
      startplacename: PickupAddress,
      startplacecountrycode: PickupCountry,
      destinationName: String(Dropoflatitude + "," + Dropoflongitude),
      destinationplacename: DropofAddress,
      destinationplacenamecountrycode: DropOffCountry,
      arrtime: formatTimeWithAMPM(otherData.pickupTime),
      retdate: moment(otherData.DropOffDate).format("YYYY-MM-DD"),
      rettime: formatTimeWithAMPM(otherData.DropOffTime),
    };
    setIsLoading(true);
    try {
      const response = await Axios.post(
        endpoint + "/api/transfers_search_react",
        data,
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
      setIsLoading(false);
      if (response.data.message === "Success") {
        dispatch(TransferSearchResponse(response.data));
        navigate("/transfer-search");
      } else {
        toast.error(response.data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error:", error);
    }
  };

  function formatTimeWithAMPM(timeString) {
    const time = moment(timeString, "HH:mm");
    return time.format("hh:mma");
  }
  return (
    <>
      {isLoading && <Loading status={true} />}
      <ToastContainer />

      <div class="tab-pane " id="bus" role="tabpanel" aria-labelledby="bus-tab">
        <div class="row">
          <div class="col-lg-12">
            <div class="tour_search_form">
              
                <div class="row">
                  <div class="col-lg-12">
                    <div class="oneway_search_form">
                    
                        <div class="row">
                          <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div class="flight_Search_boxed">
                              <p>PICK-UP LOCATION</p>
                              <Autocomplete
                  className="form-control search-form-control text-start select-styling "
                  placeholder="Enter Location"
                  name="Pickup"
                  apiKey="AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY"
                  onPlaceSelected={(place) => {
                    setPickUp(place);
                  }}
                  options={{
                    types: ["establishment", "geocode"],
                    componentRestrictions: null,
                  }}
                />
                          
                              <div class="plan_icon_posation">
                                <i class="fas fa-bus"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-3 col-md-6 col-sm-12 col-12">
                            <div class="flight_Search_boxed">
                              <p>DROP-OFF LOCATION</p>
                              <Autocomplete
                  className="form-control search-form-control text-start select-styling "
                  placeholder="Enter Location"
                  name="Dropoff"
                  apiKey="AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY"
                  onPlaceSelected={(place) => {
                    setDropOf(place);
                  }}
                  options={{
                    types: ["establishment", "geocode"],
                    componentRestrictions: null,
                  }}
                />
                              <div class="plan_icon_posation">
                                <i class="fas fa-bus"></i>
                              </div>
                              <div class="range_plan">
                                <i class="fas fa-exchange-alt"></i>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6  col-md-6 col-sm-12 col-12">
                            <div class="form_search_date">
                              <div class="flight_Search_boxed date_flex_area">
                                <div class="Journey_date">
                                  <p>PICK-UP DATE</p>
                                  <DatePicker
                  onChange={handlePickupDate}
                  selected={otherData.pickupDate}
                  placeholderText="Select Date"
                  name="pickupDate"
                  minDate={new Date()}
                  dateFormat="dd/MM/yyyy" // Customize date format as needed
                  className="form-control text-start "
                />
                                  
                                </div>
                                <div class="Journey_date">
                                  <p>DROP-OFF DATE</p>
                                  <DatePicker
                  onChange={handleDropoffDate}
                  selected={otherData.DropOffDate}
                  placeholderText="Select Date"
                  minDate={new Date()}
                  name="dropoffDate"
                  dateFormat="dd/MM/yyyy" // Customize date format as needed
                  className="form-control text-start "
                />
                                  
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-lg-6  col-md-6 col-sm-12 col-12 mt-3">
                            <div class="form_search_date">
                              <div class="flight_Search_boxed date_flex_area">
                                <div class="Journey_date">
                                  <p>PICK-UP TIME</p>
                                  <input
                  type="time"
                  class="form-control"
                  name="pickupTime"
                  value={otherData.pickupTime}
                  onChange={handleOtherData}
                />
                                 
                                </div>
                                <div class="Journey_date">
                                  <p>DROP-OFF TIME</p>
                                  <input
                  type="time"
                  class="form-control"
                  name="DropOffTime"
                  value={otherData.DropOffTime}
                  onChange={handleOtherData}
                />
                                  
                                </div>
                              </div>
                            </div>
                          </div>


                          <div class="col-lg-2  col-md-6 col-sm-12 col-12 mt-3">
                            <div class="flight_Search_boxed dropdown_passenger_area">
                              <p>TRIP TYPE </p>
                              <div class="dropdown">
                              <select
                  onChange={handleSelectTripType}
                  value={tripType}
                  className="form-select select-styling"
                  aria-label="Default select example"
                >
                  <option selected value="One-Way">
                    One-Way
                  </option>

                  <option value="Return">Return</option>
                  <option value="All_Round">All_Round</option>
                </select>
                               
                              </div>
                             
                            </div>
                          </div>
                          <div class="col-lg-2  col-md-6 col-sm-12 col-12 mt-3">
                            <div class="flight_Search_boxed dropdown_passenger_area">
                              <p>PASSENGER </p>
                              <div class="dropdown">
                              <input
                  type="number"
                  min={1}
                  onChange={handlePassenger}
                  value={Passenger}
                  placeholder="Passenger"
                  className="form-control select-styling"
                />
                               
                              </div>
                             
                            </div>
                          </div>
                          <div class="col-lg-2  col-md-6 col-sm-12 col-12 mt-3">
                            <div class="flight_Search_boxed dropdown_passenger_area">
                              <p>VEHICLES </p>
                              <div class="dropdown">
                              <input
                  type="number"
                  min={0}
                  onChange={handleVehicles}
                  value={Vehicles}
                  placeholder="Vehicles"
                  className="form-control select-styling"
                />
                               
                              </div>
                             
                            </div>
                          </div>

                          <div class="top_form_search_button">
                            <button class="btn btn_theme btn_md" 
                              type="submit"
                              onClick={searchTransfer}
                              value="Search">Search</button>
                          </div>
                        </div>
                     
                    </div>
                  </div>
                </div>
             
            </div>
          </div>
        </div>
      </div>

      {/* <div class='form  custom-search-home justify-content-start'>
        <div class='g-field-search'>
        <div className='row pb-2'>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  PICK-UP LOCATION
                </label>
                <div className='field-icon-wrap'>
                  <Autocomplete
                    className='form-control search-form-control text-start select-styling '
                    placeholder='Enter Location'
                    name='Pickup'
                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                    onPlaceSelected={place => {
                      setPickUp(place)
                    }}
                    options={{
                      types: ['establishment', 'geocode'],
                      componentRestrictions: null
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  DROP-OFF LOCATION
                </label>
                <div className='field-icon-wrap'>
                
                  <Autocomplete
                    className='form-control search-form-control text-start select-styling '
                    placeholder='Enter Location'
                    name='Dropoff'
                    apiKey='AIzaSyBmwlQFQKwxZ4D8nRbsWVRTBFUHMO-lUOY'
                    onPlaceSelected={place => {
                      setDropOf(place)
                    }}
                    options={{
                      types: ['establishment', 'geocode'],
                      componentRestrictions: null
                    }}
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  PICK-UP DATE
                </label>
                <div className='field-icon-wrap date_input_width'>
                 
                  <DatePicker
                    onChange={handlePickupDate}
                    selected={otherData.pickupDate}
                    placeholderText='Select Date'
                    name='pickupDate'
                    minDate={new Date()}
                    dateFormat='dd/MM/yyyy' // Customize date format as needed
                    className='form-control text-start '
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  PICK-UP Time
                </label>
                <div className='field-icon-wrap'>
                  <input
                    type='time'
                    class='form-control'
                    name='pickupTime'
                    value={otherData.pickupTime}
                    onChange={handleOtherData}
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  DROP-OFF DATE
                </label>
                <div className='field-icon-wrap date_input_width'>
                 
                  <DatePicker
                    onChange={handleDropoffDate}
                    selected={otherData.DropOffDate}
                    placeholderText='Select Date'
                    minDate={new Date()}
                    name='dropoffDate'
                    dateFormat='dd/MM/yyyy' // Customize date format as needed
                    className='form-control text-start '
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  DROP-OFF Time
                </label>
                <div className='field-icon-wrap'>
                  <input
                    type='time'
                    class='form-control'
                    name='DropOffTime'
                    value={otherData.DropOffTime}
                    onChange={handleOtherData}
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  TRIP TYPE
                </label>
                <div className='field-icon-wrap'>
                  <select
                    onChange={handleSelectTripType}
                    value={tripType}
                    className='form-select select-styling'
                    aria-label='Default select example'
                  >
                    <option selected value='One-Way'>
                      One-Way
                    </option>

                    <option value='Return'>Return</option>
                    <option value='All_Round'>All_Round</option>
                  </select>
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  PASSENGER
                </label>
                <div className='field-icon-wrap'>
                  <input
                    type='number'
                    min={1}
                    onChange={handlePassenger}
                    value={Passenger}
                    placeholder='Passenger'
                    className='form-control select-styling'
                  />
                </div>
              </div>
              <div className='col-md-6  mb-lg-0  mt-2 col-lg-3'>
                <label
                  htmlFor='checkin_date'
                  className='font-weight-bold text-black mb-2'
                >
                  Vehicles
                </label>
                <div className='field-icon-wrap'>
                  <input
                    type='number'
                    min={0}
                    onChange={handleVehicles}
                    value={Vehicles}
                    placeholder='Vehicles'
                    className='form-control select-styling'
                  />
                </div>
              </div>
              <div className='col-md-6 col-lg-3 text-center align-self-end'>
              <input type='submit'  onClick={searchTransfer} class='btn_search' value='Search' />
              </div>
            </div>
        </div>
      </div> */}
    </>
  );
}

export default TransferNew;
