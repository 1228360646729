export function Hotelapitoken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};

export function CustomerDomainName(){
    return 'https://admin.hashimtravel.com/' 
};
export function FlightSearchToken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};

export function ApiEndPoint(){
    // return 'https://liveapi.synchtravel.com'
    return 'https://api.synchtravel.com'
    
};
export function TransferApiToken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};
export function NewTransferSearchToken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};
export function TransferCheckoutToken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};

export function CurrencyConverter(){
    return 'b8d4b285d48d1e690388e999'
};
export function StripeClientSecret(){
    return 'sk_test_51N9pi8GwYlRApJ0wzqUOVIJBpCNJ1Lz7UcUjJoSdMU73WAVzoTXSJ1gfakCYThPWhnzWlKnLLQXugdB0s8dBFDJ500Beg22CmF'
    // return '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'
};
export function ActivityToken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};
export function HajjApiToken(){
    return 'dMHmbbV614wwXAptkKaAYXZU7VNi4iH9gbOWJvWF-x3rTIsaFM7syf50B7HOrozkGkGXSmMjNaBn8qz1U2oGzqtWqNgn6jBIncZzX-M2uKQ6bBkcHeD4T1dtVfHeH74-qdHpj27fSm2lMy0PfIgGiWjGdNtQb0LaQ4ICnlxfc73jban5uXB9mNdBoHsmaJcFtpq6B6trJeu-EI29jFgUNMiCzmbDwlRHEHPiXyC1LFVuHH6zf4wu7owKUsho6DGq2xFFk6QUniJOkzWtK0w4wyC3oXlKQUqPRBQnx0Swf2fBSmOL-y7hQluZchgiQhV40EkU9yrGaDRJKyzh1fYAfTDaAtCsvX7vFl42YQPbOxokFcSI2vRySMzVviyH-1Fc3263a4ddrmdJSdnJzaSoue-Ztl1gQiwZfY5sEYqTlzyGojfnOUgFHoeUAEOE8IAoDTEIXjrjU-Qmw8e2mvOhYw4XqFYCpQp7G8ufh5Qb3y3QgAwKknE3h7LuwkIr'
};


// b8d4b285d48d1e690388e999 with 30k hits
//32ce58d166a15bca2a55cd7a testing
// 8885028c6faaef9cb5bc397b new