import React, { useEffect, useState } from 'react'
import Googlemap from '../../Components/Googlemap/Googlemap'
import img2 from '../../Images/New/bg-contact.jpg'
import Layout from '../../Components/Layout/Layout'
import cimg from '../../img/banner/common-banner.png'
import wow from 'wowjs'
import { ToastContainer, toast } from 'react-toastify'
import emailjs from 'emailjs-com'
import { NavLink } from 'react-router-dom'
const Contact = () => {
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    subject: '',
    phnno: '',
    message: ''
  })
  const [isBooking, setIsBooking] = useState(false)
  useEffect(() => {
    new wow.WOW().init()
  }, [])

  const handleChange = event => {
    const { value, name } = event.target
    setFormData(prevdata => ({
      ...prevdata,
      [name]: value
    }))
  }

  const isValidEmail = email => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    return emailRegex.test(email)
  }

  const submitForm = async () => {
    if (formData.name === '') {
      toast.info('Please Enter Your Name.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.email === '') {
      toast.info('Please Enter Your Email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.subject === '') {
      toast.info('Please Enter Subject.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.phnno === '') {
      toast.info('Please Enter Phone Number.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (formData.message === '') {
      toast.info('Please Enter Message.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    } else if (!isValidEmail(formData.email)) {
      toast.info('Please Enter a valid email.', {
        position: toast.POSITION.TOP_RIGHT
      })
      return
    }

    setIsBooking(true)
    await sendUserMessage()
    setIsBooking(false)
    toast.success(
      'Dear Customer,Thank you for contacting AlHijaz Tours.One of our agent will contact you within 12 to 24 hours.'
    )
    // try {
    //     const templateParams = {
    //       email: formData.email,
    //       name: formData.name,
    //       subject: formData.subject,
    //       phone : formData.phnno,
    //       message: formData.message
    //     };

    //     await emailjs.send('service_d6zrc2l', 'template_la4ks0l', templateParams, 'c163bgNie5rW0iU1f');
    //     setIsBooking(false);
    //     toast.success('Email Send Successfully.', {
    //         position: toast.POSITION.TOP_RIGHT
    //       });
    //   } catch (error) {
    //     setIsBooking(false);
    //     console.error('Error sending email', error);
    //     toast.error('Error sending email.', {
    //         position: toast.POSITION.TOP_RIGHT
    //       });
    //   }
  }

  const sendUserMessage = async () => {
    const messages = [
      {
        recipient: '+07311398717',
        message: `Dear Dar Riaz Travel Limited,
              
          A customer has contact via contact form.
              
          Name:${formData.name}
          Email:${formData.email}
          Number:${formData.phnno}
          Subject:${formData.subject}
          Message:${formData.message}
              
          Thank You`
      },
      {
        recipient: formData.phnno,
        message: `Dear Customer,\nThank you for contacting Dar Riaz Travel Limited.\nOne of our agent will contact you within 12 to 24 hours.
            
        Thank You 
        Team Dar Riaz Travel Limited`
      }
    ]
    const apiKey = 'RUU6Wkzm2JRt'
    for (let i = 0; i < messages.length; i++) {
      const { recipient, message } = messages[i]
      const encodedMessage = encodeURIComponent(message)
      const url = `http://api.textmebot.com/send.php?recipient=${recipient}&apikey=${apiKey}&text=${encodedMessage}`

      try {
        const response = await fetch(url)

        if (response.ok) {
          const contentType = response.headers.get('content-type')

          if (contentType && contentType.includes('application/json')) {
            const data = await response.json()
            console.log(`Message sent to ${recipient}:`, data)
          } else {
            const text = await response.text()
            console.log(`Message sent to ${recipient}:`, text)
          }

          // Wait 7 seconds before sending the next message
          if (i < messages.length - 1) {
            await new Promise(resolve => setTimeout(resolve, 7000))
          }
        } else {
          console.error(
            `Error sending message to ${recipient}:`,
            response.statusText
          )
        }
      } catch (error) {
        if (i < messages.length - 1) {
          await new Promise(resolve => setTimeout(resolve, 7000))
        }
        console.error(`Error sending message to ${recipient}:`, error)
      }
    }
  }

  const sectionStyle = {
    background: `url(${cimg}) no-repeat`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height : "100%"

  }; 


  return (
    <>
    <ToastContainer/>
      <Layout>
      <section id="common_banner" style={sectionStyle}>
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="common_bannner_text">
                        <h2>Contact</h2>
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><span><i class="fas fa-circle"></i></span>Contact</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* <!-- Contact Area --> */}
    <section id="contact_main_arae" class="section_padding">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="section_heading_center">
                        <h2>Contact with us</h2>
                    </div>
                </div>
            </div>
            <div class="contact_main_form_area_two">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="contact_left_top_heading">
                            <h2>Do you have any query? Contact with us to get any
                                any support.</h2>
                                <h3>Leave us a message</h3>
                            <p>Hashim Travel is the pioneer tour operation in United Kingdom to offer Hajj and Umrah services from United Kingdom. Make use of our fantastic hajj and umrah packages which are tailored to suit the needs of pilgrims visiting Saudi Arabia for their all important sacred journey.</p>
                        </div>
                        <div class="contact_form_two">
                <div id="contact_form_content">
                            
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input" placeholder="First name*" onChange={handleChange} name='name'/>
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input" placeholder="Subject"  onChange={handleChange} name='subject' />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="email" class="form-control bg_input"
                                                placeholder="Email address* "  onChange={handleChange}  name="email"  id="email_contact" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <input type="text" class="form-control bg_input"
                                                placeholder="Mobile number*" onChange={handleChange} name='phoneno' />
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <textarea class="form-control bg_input" rows="5"
                                                placeholder="Message" onChange={handleChange} name="message"></textarea>
                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="form-group">
                                            <button class="btn btn_theme btn_md"  type="submit" onClick={submitForm} id="submit-contact" value="Submit">Send message</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="contact_two_left_wrapper">
                            <h3>Contact details</h3>
                            <div class="contact_details_wrapper">
                                <div class="contact_detais_item">
                                    <h4>Help line</h4>
                                    
                                    <a href="tel:+07877 285517"><h3>07877 285517</h3></a>
                                </div>
                                <div class="contact_detais_item">
                                    <h4>Support mail</h4>
                                   
                                    <a href="mailto:hashimtravel@hotmail.co.uk"><h3>hashimtravel@hotmail.co.uk</h3></a>
                                </div>
                                <div class="contact_detais_item">
                                    <h4>Location</h4>
                                    <h3>36 Audley Range, 
                                    Blackburn, Lancashire, BB1 1TD7</h3>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
      
      </Layout>
    </>
  )
}
const Spinner = () => {
  return (
    <div className='spinner-border' role='status'>
      <span className='visually-hidden'>Loading...</span>
    </div>
  )
}
export default Contact
